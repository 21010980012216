<template>
  <div v-if="loading" class="loader">
    <loader></loader>
  </div>
  <v-card v-else fluid :dark="$dark.get()">
    <v-card-title class="pt-2 pb-2">
      {{ $route.name }}
    </v-card-title>
    <v-card-text style="padding-top: 2.5px">
      <v-row>
        <v-col cols="12" class="pt-0">
          <yandex-map
            :zoom="map_d.zoom"
            :settings="map_settings"
            :coords="map_d.coords"
            map-type="map"
            @boundschange="map_boundschange"
            @map-was-initialized="initMap"
          >
          </yandex-map>
        </v-col>
        <v-col cols="12">
          <v-row>
            <v-col cols="2" class="text-right pb-0 pt-0"> Филиал </v-col>
            <v-col cols="9" class="pb-0 pt-0">
              <v-select
                v-model="department_active"
                :items="department"
                menu-props="offsetY"
                item-value="id"
                item-text="name"
                :disabled="single_zone.id != 0"
                outlined
                dense
                :rules="[$validate.required]"
                @change="changeDep()"
              ></v-select>
            </v-col>
            <v-col cols="2" class="text-right pb-0 pt-0">
              Наименование района
            </v-col>
            <v-col cols="9" class="pb-0 pt-0">
              <v-text-field
                v-model="single_zone.name"
                outlined
                dense
                :rules="[$validate.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="2" class="text-right pb-0 pt-0">
              Стоимость доставки
            </v-col>
            <v-col cols="9" class="pb-0 pt-0">
              <v-text-field
                v-model="single_zone.price"
                outlined
                dense
                :rules="[$validate.float]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-card-actions class="justify-end">
            <v-btn @click="$router.replace('/area')">Отмена</v-btn>
            <v-btn
              v-if="buttonType === 'Сохранить'"
              color="error"
              @click="show = true"
              >Удалить</v-btn
            >
            <v-btn color="primary" @click="buttonMeth">{{ buttonType }}</v-btn>
          </v-card-actions>
        </v-col>
      </v-row>
    </v-card-text>
    <delete-dialog
      :show="show"
      @close-modal="show = !show"
      @delete-modal="deleteZone()"
    />
  </v-card>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Loader from "../components/Loader";
import DeleteDialog from "@/components/DeleteDialog";
export default {
  components: { Loader, DeleteDialog },
  data() {
    return {
      myMap: {},
      all_zone: {
        coords: [],
        name: "",
        price: "",
        id: "",
      },
      polygon_list: [],
      active_edit_polygon_id: 0,
      edit_polygon_data: [],
      edit_polygon: {},
      add_polygon: {},
      single_zone: {
        coords: [],
        name: "",
        price: "",
        id: "",
      },
      stateMonitor: {},
      map_d: {
        zoom: 11,
        coords: [49.783936, 73.145232],
      },
      loading: false,
      map_settings: {
        apiKey: "",
        lang: "ru_RU",
        coordorder: "latlong",
        version: "2.1",
      },
      btn: {
        add: {},
        edit: {},
        save: {},
        remove: {},
        close: {},
      },
      buttonType: "Добавить",
      buttonMod: "add",
      dialog: false,
      department: [],
      department_active: 0,
      show: false,
    };
  },
  computed: {
    ...mapGetters({
      DEPARTMENT: "Department/STATE",
      ZONE: "Zone/STATE",
    }),
  },
  watch: {
    all_zone: {
      handler() {
        this.add_polygon_list();
      },
      deep: true,
    },
  },
  mounted() {
    this.$eventBus.$on("change-city", this.changeCity);
  },
  async created() {
    this.loading = true;
    await this.getAllDepartment(this.$root.city);
    this.department = this.DEPARTMENT.cityDepartment;
    this.all_zone = await this.getAllZoneList(this.department[0].id);
    this.department_active = this.department[0].id;
    if (this.$route.params.id) {
      this.department_active = parseFloat(this.$route.params.id.split("-")[1]);
      this.single_zone = await this.getSingleZone(
        this.$route.params.id.split("-")[0]
      );
      this.all_zone = await this.getAllZoneList(
        this.$route.params.id.split("-")[1]
      );
      this.buttonType = "Сохранить";
      this.buttonMod = "edd";
    }
    this.loading = false;
  },
  methods: {
    ...mapActions({
      getSingleZone: "Zone/GET_SINGLE_ZONE",
      addNewZone: "Zone/ADD_NEW_ZONE",
      removeZone: "Zone/REMOVE_ZONE",
      updateZone: "Zone/UPDATE_ZONE",
      getAllDepartment: "Department/GET_DEPARTMENT",
      DepartmentZoneSave: "Zone/DEPARTMENT_ZONE_SAVE",
      getAllZoneList: "Zone/GET_ALL_ZONE_LIST",
    }),
    map_boundschange(e) {
      if (parseInt(e._cache.newZoom) > 0) {
        this.map_d.zoom = e._cache.newZoom;
      }
      if (typeof e._cache.newCenter.isArray === "undefined") {
        this.map_d.coords = e._cache.newCenter;
      }
    },
    initMap(map) {
      this.myMap = map;
      this.add_polygon_list();

      this.btn.add = new ymaps.control.Button({
        data: {
          content: "Создать",
        },
        options: {
          layout: ymaps.templateLayoutFactory.createClass(
            [
              '<div class="ymap_btn ymap_btn--blue">{{ data.content }}</div>',
            ].join("")
          ),
        },
      });
      this.btn.edit = new ymaps.control.Button({
        data: {
          content: "Редактировать",
        },
        options: {
          layout: ymaps.templateLayoutFactory.createClass(
            [
              '<div class="ymap_btn ymap_btn--yellow">{{ data.content }}</div>',
            ].join("")
          ),
        },
      });
      if (this.$route.params.id == undefined) {
        this.myMap.controls.add(this.btn.add, { float: "right" });
        this.btn.add.events.add("click", (e) => {
          this.create_polygon();
          this.myMap.controls.remove(this.btn.add);
        });
        this.myMap.controls.add(this.btn.add, { float: "right" });
      } else {
        this.myMap.controls.add(this.btn.edit, { float: "right" });
        this.btn.edit.events.add("click", (e) => {
          this.myMap.controls.remove(this.btn.edit);
          this.$set(this, "edit_polygon_data", this.single_zone); // Записали на редактирование
          this.start_edit_polygon();
        });
      }
    },
    start_edit_polygon() {
      var coords = this.edit_polygon_data.coords;
      if (typeof coords == "string")
        coords = JSON.parse(this.edit_polygon_data.coords);
      this.edit_polygon = new ymaps.Polygon([coords], {
        editorDrawingCursor: "crosshair",
        fillColor: "#6699ff",
        // Делаем полигон прозрачным для событий карты.
        interactivityModel: "default#transparent",
        strokeWidth: 8,
        opacity: 0.5,
      });

      this.myMap.geoObjects.add(this.edit_polygon);
      this.stateMonitor = new ymaps.Monitor(this.edit_polygon.editor.state);
      this.stateMonitor.add("drawing", (newValue) => {
        this.edit_polygon.options.set(
          "strokeColor",
          newValue ? "#bcc1c1" : "#0000FF"
        );
      });
      this.edit_polygon.editor.startDrawing();
      this.myMap.setBounds(this.edit_polygon.geometry.getBounds());
    },
    create_polygon() {
      // Создаем полигон для карты
      this.$set(this, "edit_polygon_data", {
        id: 0,
        coords: [],
        name: "",
        price: 0,
      });

      this.edit_polygon = new ymaps.Polygon(
        [],
        {},
        {
          editorDrawingCursor: "crosshair",
          fillColor: "#00adff",
          strokeWidth: 3,
          opacity: 0.5,
        }
      );

      this.myMap.geoObjects.add(this.edit_polygon);
      this.stateMonitor = new ymaps.Monitor(this.edit_polygon.editor.state);
      this.stateMonitor.add("drawing", (newValue) => {
        this.edit_polygon.options.set(
          "strokeColor",
          newValue ? "#bcc1c1" : "#0000FF"
        );
      });

      this.edit_polygon.editor.startDrawing();
    },
    add_polygon_list() {
      // Создает из массива список полигонов на карте
      // Сначала удаляем все полигоны
      for (var i = 0; i < this.polygon_list.length; i++) {
        this.myMap.geoObjects.remove(this.polygon_list[i]);
      }

      if (typeof this.myMap["geoObjects"] == "undefined") return false;
      for (var i = 0; i < this.all_zone.length; i++) {
        var coords = this.all_zone[i].coords;
        if (typeof coords == "string")
          coords = JSON.parse(this.all_zone[i].coords);

        this.polygon_list[i] = new ymaps.Polygon(
          [coords],
          {
            hintContent: this.all_zone[i].name,
          },
          {
            fillColor: "#00adff",
            interactivityModel: "default#transparent",
            strokeWidth: 1,
            opacity: 0.5,
          }
        );
        this.myMap.geoObjects.add(this.polygon_list[i]);
        this.polygon_list[i].id = this.all_zone[i].zoneId;
      }
    },
    get_edit_polygon_data() {
      // Возвращает данные текущего редактируемого полигона
      var data = [];
      this.myMap.geoObjects.each((GeoObject) => {
        if (this.single_zone.id > 0) {
          if (GeoObject.id == this.single_zone.id)
            data = GeoObject.geometry.getCoordinates()[0];
        }
        if (GeoObject.properties._data.hintContent === undefined) {
          data = GeoObject.geometry.getCoordinates()[0];
        }
      });
      return data;
    },
    async savePolygon() {
      this.single_zone.coords = this.get_edit_polygon_data();
      let response = await this.updateZone(this.single_zone);
      if (response.type == "success") {
        this.$router.push("/area");
      } else {
        this.$notify({
          group: "app",
          type: "error",
          title: "Действие не успешно",
          text: response.text,
        });
      }
    },
    async addPolygon() {
      this.single_zone.coords = this.get_edit_polygon_data();
      let response = await this.addNewZone(this.single_zone);
      if (this.department_active > 0) {
        let zonesaveresp = await this.DepartmentZoneSave({
          departmentId: this.department_active,
          zoneId: response.data.id,
        });
      }
      if (response.type == "success") {
        this.$router.push("/area");
      } else {
        this.$notify({
          group: "app",
          type: "error",
          title: "Действие не успешно",
          text: response.data.message,
        });
      }
    },
    async buttonMeth() {
      if (this.buttonMod == "add") {
        await this.addPolygon();
      }
      if (this.buttonMod == "edd") {
        await this.savePolygon();
      }
    },
    async changeDep() {
      this.all_zone = await this.getAllZoneList(this.department_active);
    },
    async deleteZone() {
      let resp = await this.removeZone(this.single_zone.id);
      if (resp.type === "success") {
        this.$router.replace("/area");
      }
    },
  },
};
</script>
<style lang="scss">
.ymap-container {
  @media screen and (max-width: 1920px) {
    min-height: 29vmax;
    height: 70%;
  }
  @media screen and (max-width: 1440px) {
    min-height: 450px;
    height: 50%;
  }
  @media screen and (max-width: 1366px) {
    min-height: 320px;
    height: 30%;
  }
}
.ymap_btn {
  background-color: #fff;
  border-color: transparent;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.15),
    0 2px 5px -3px rgba(0, 0, 0, 0.15);
  box-sizing: border-box !important;
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
  background-clip: border-box;
  color: #000;
  vertical-align: middle;
  text-decoration: none;
  font-family: Arial, Helvetica, sans-serif;
  cursor: pointer;
  -webkit-transition: background-color 0.15s ease-out,
    border-color 0.15s ease-out, opacity 0.15s ease-out;
  transition: background-color 0.15s ease-out, border-color 0.15s ease-out,
    opacity 0.15s ease-out;
  display: inline-block;
  text-align: left;
  height: 28px;
  -ms-user-select: none; /* Internet Explorer */
  -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
  -webkit-user-select: none; /* Chrome, Safari, and Opera */
  -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
  padding: {
    top: 4px;
    left: 10px;
    right: 10px;
  }

  &--blue {
    color: #fff;
    background-color: rgb(85, 201, 255);
  }
  &--red {
    color: #fff;
    background-color: #f55;
  }
  &--yellow {
    color: #fff;
    background-color: #f4c712;
  }
  &--green {
    color: #fff;
    background-color: #00c469;
  }
}
.loader {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80vh;
}
</style>
